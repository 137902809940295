<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-5xl"
      @opened="onOpened"
      @close="$emit('close')"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar Barang
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Daftar Barang Yang Telah Ditambahkan
              </p>
            </div>
          </div>
        </div>
        <div class="space-y-4 border-t border-gray-200 pt-6">
          <div class="flex gap-x-2 justify-end">
            <base-select
              v-if="withFilterStockReal"
              :shadow="false"
              :expand="false"
              :options="[
                { id: 'all', value: null, label: 'Semua' },
                { id: 'on_tock', value: true, label: 'Ada Stock' }
              ]"
              v-model="filter.has_stock_real"
              @change="loadStocks"
            />
            <base-input
              placeholder="Cari Barang"
              type="search"
              :shadow="false"
              debounce
              v-model="filter.search"
              @native-input="loadStocks"
            />
          </div>
          <datatable
            :columns="tableColumns"
            :scroll-x="false"
            :total="stocks.meta.page.total"
            :perPage="stocks.meta.page.perPage"
            :currentPage="stocks.meta.page.currentPage"
            @pagechanged="onPagechanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="stock in stocks.data"
                :key="stock.id"
                :class="[
                  classes.tr,
                  !checkStockReal || stock.attributes.stock_real > 0 ? 'cursor-pointer bg-white hover:bg-green-100' : 'bg-gray-50',
                ]"
                @click="onClickStock(stock)"
              >
                <td :class="[classes.td]">
                  <p class="font-bold text-gray-900">{{ stock.attributes.product_code }}</p>
                </td>
                <td :class="[classes.td]">
                  {{ stock.attributes.product_name }}
                </td>
                <td v-if="columns.includes('category')" :class="[classes.td]">
                  {{ stock.attributes.product_category_name }}
                </td>
                <td v-if="columns.includes('stock_real')" :class="[classes.td, 'text-right']">
                  {{ stock.attributes.stock_real | toCurrency }}
                </td>
                <td v-if="columns.includes('stock_loan_qty')" :class="[classes.td, 'text-right']">
                  {{ stock.attributes.stock_loan_qty | toCurrency }}
                </td>
                <td v-if="columns.includes('weight')" :class="[classes.td, 'text-right']">
                  {{ stock.attributes.stock_weight | toCurrency }}
                </td>
                <td v-if="columns.includes('price')" :class="[classes.td, 'text-right']">
                  {{ stock.attributes.product_price | toCurrency }}
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
  },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    requestParams: null,
    withFilterStockReal: Boolean,
    columns: {
      type: Array,
      default: () => ['category', 'stock_real']
    },
    warehouseId: String,
    checkStockReal: {
      type: Boolean,
      default: true
    },
    checkStockLoan: Boolean
  },
  emits: ['close', 'click-stock'],
  data() {
    return {
      stocks: {
        data: [],
        meta: {
          page: {},
        },
      },
      loading: false,
      filter: {
        search: null,
        has_stock_real: true
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser'
    }),
    tableColumns() {
      return [
        {
          id: 'code',
          name: 'Kode Barang',
        },
        {
          id: 'name',
          name: 'Nama Barang',
        },
        this.columns.includes('category') ? {
          id: 'category',
          name: 'Kelompok Barang',
        } : null,
        this.columns.includes('stock_real') ? {
          id: 'stock_real',
          name: 'Stock',
          theadClass: 'text-right'
        } : null,
        this.columns.includes('stock_loan_qty') ? {
          id: 'stock_loan_qty',
          name: 'Stock Restock',
          theadClass: 'text-right'
        } : null,
        this.columns.includes('weight') ? {
          id: 'weight',
          name: 'Berat',
          theadClass: 'text-right'
        } : null,
        this.columns.includes('price') ? {
          id: 'price',
          name: 'Harga',
          theadClass: 'text-right'
        } : null,
      ].filter((item) => !!item);
    },
  },
  methods: {
    async loadStocks(params) {
      this.loading = true;

      const stockFields = [
        'product',
        'product_code',
        'product_name',
        ...(this.columns.includes('category') ? ['product_category_code,product_category_name'] : []),
        ...(this.columns.includes('stock_real') ? ['stock_real'] : []),
        ...(this.columns.includes('stock_loan_qty') ? ['stock_loan_qty'] : []),
        ...(this.columns.includes('weight') ? ['stock_weight'] : []),
        ...(this.columns.includes('price') ? ['product_price'] : [])
      ].join(',')
      
      const [res, error] = await this.request(`/api/v1/warehouses/${this.warehouseId || this.me.current_warehouse}/stocks`, {
        params: {
          ...this.requestParams,
          'page[size]': 5,
          'fields[stocks]': stockFields,
          'fields[products]': 'code',
          'filter[search]': this.filter.search,
          ...(this.checkStockReal ? { 'filter[has_stock_real]': this.filter.has_stock_real } : {}),
          ...(this.checkStockLoan ? { 'filter[has_stock_loan]': true } : {}),
          'include': 'product',
          ...params,
        },
      });

      if (!error) {
        this.stocks = res;
      }

      this.loading = false;
    },
    onClickStock(stock) {
      if (!this.checkStockReal || stock.attributes.stock_real > 0) {
        this.$emit('click-stock', stock)
      }
    },
    onOpened() {
      this.filter.search = null;
      this.filter.has_stock_real = true;

      this.loadStocks();
    },
    onPagechanged(page) {
      this.loadStocks({
        'page[number]': page,
      });
    },
  },
};
</script>
