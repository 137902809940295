<template>
  <div class="relative">
    <input
      ref="order_detail_code"
      type="text"
      :disabled="disabled"
      class="w-full border-0 p-0 placeholder-gray-200 focus:border-0 focus:ring-0 sm:text-sm"
      placeholder="Kode Barang"
      v-model="search"
      @change="onSearchProduct"
    />
    <button
      v-if="!disabled"
      type="button"
      @click="onOpenStockModal"
      class="absolute inset-y-0 top-0 right-0 flex cursor-pointer items-center pl-3"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-500 hover:text-gray-700"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
        <path
          fill-rule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <stock-modal
      v-if="type === 'stock'"
      :visible="modal.visible"
      :columns="stockModalColumns"
      :warehouse-id="originWarehouse.id"
      :request-params="stockRequestParams"
      :check-stock-real="checkStockReal"
      :check-stock-loan="checkStockLoan"
      @close="modal.visible = false"
      @click-stock="onChangeProduct"
    />
    <product-modal
      v-else
      :visible="modal.visible"
      :office-id="originOffice.id"
      @close="modal.visible = false"
      @click-product="onChangeProduct"
    />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import StockModal from '@/components/stock/stock-modal.vue';
import ProductModal from '@/components/product/product-modal.vue';
import { mapActions } from 'vuex';

export default {
  mixins: [requestMixin],
  components: {
    StockModal,
    ProductModal,
  },
  props: {
    disabled: Boolean,
    value: null,
    originalSearch: String,
    type: {
      type: String,
      default: 'stock',
    },
    originOffice: Object,
    originWarehouse: Object,
    stockRequestParams: Object,
    stockModalColumns: {
      type: Array,
      default: () => ['weight', 'price', 'stock_real']
    },
    checkStockReal: {
      type: Boolean,
      default: true
    },
    checkStockLoan: Boolean
  },
  emits: ['input', 'update:loading', 'change'],
  data() {
    return {
      modal: {
        index: null,
        visible: false,
      },
    };
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onChangeProduct(stockOrProduct) {
      this.$emit('change', {
        product:
          this.type === 'stock'
            ? stockOrProduct.relationships.product.data
            : stockOrProduct,
        stock: stockOrProduct,
        closeModal: () => (this.modal.visible = false),
      });
    },
    onOpenStockModal(index) {
      this.modal.index = index;
      this.modal.visible = true;
    },
    async onSearchProduct() {
      this.$emit('update:loading', true);

      const [stockOrProduct, err] = await this.searchProduct();

      if (err) {
        this.search = this.originalSearch;

        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('change', {
          stock: this.type === 'stock' ? stockOrProduct.stock : null,
          product:
            this.type === 'stock' ? stockOrProduct.product : stockOrProduct,
        });
      }

      this.$emit('update:loading', false);
    },
    async searchProduct() {
      const [res, err] =
        this.type === 'stock'
          ? await this.request(
              `/api/v1/warehouses/${this.originWarehouse.id}/stocks`,
              {
                params: {
                  'fields[stocks]': 'product',
                  'fields[products]': 'code',
                  'filter[product_code]': this.search,
                  include: 'product',
                },
              }
            )
          : await this.request(
              `/api/v1/offices/${this.originOffice.id}/products`,
              {
                params: {
                  'filter[code]': this.search,
                },
              }
            );

      if (err || !res.data.length) {
        return [null, err || 'Produk tidak ditemukan'];
      }

      if (this.type === 'stock') {
        return [
          {
            stock: res.data[0],
            product: this.getSingleIncluded(
              res,
              res.data[0].relationships.product.data.id
            ),
          },
          null,
        ];
      }

      return [res.data[0], null];
    },
  },
};
</script>
