<template>
  <div>
    <div class="space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
      <div class="border-b border-gray-200 pb-5">
        <h3 class="text-lg font-bold leading-6 text-gray-900">Barang</h3>
        <p class="mt-2 max-w-4xl text-sm text-gray-500">
          Tambah informasi barang.
        </p>
      </div>
      <Datatable id="form_barang" :paginated="false" :footer="false">
        <template v-slot:thead>
          <thead :class="colors.thead">
            <tr>
              <th
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                KODE BARANG
              </th>
              <th
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                NAMA BARANG
              </th>
              <th
                v-if="columns.includes('product_type')"
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                Tipe Barang
              </th>
              <th
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                STOCK
              </th>
              <th
                v-if="columns.includes('product_orderable')"
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                STATUS ORDER
              </th>
              <th
                v-if="columns.includes('product_is_point')"
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                P/N
              </th>
              <th
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                JUMLAH
              </th>
              <th
                v-if="columns.includes('product_point')"
                scope="col"
                :class="[
                  'px-6 py-3 text-right text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                @PV
              </th>
              <th
                v-if="columns.includes('total_point')"
                scope="col"
                :class="[
                  'px-6 py-3 text-right text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                PV
              </th>
              <th
                v-if="columns.includes('product_bonus')"
                scope="col"
                :class="[
                  'px-6 py-3 text-right text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                @BV
              </th>
              <th
                v-if="columns.includes('total_bonus')"
                scope="col"
                :class="[
                  'px-6 py-3 text-right text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                BV
              </th>
              <th
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                harga
              </th>
              <th
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                total harga
              </th>
              <th
                v-if="columns.includes('total_weight')"
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-right text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                total berat
              </th>
              <th
                scope="col"
                :class="[
                  'px-6 py-3 text-left text-xs font-medium uppercase tracking-wider',
                  colors.th,
                ]"
              >
                AKSI
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody>
            <tr
              class="bg-white"
              v-for="(orderDetail, index) in orderDetails"
              :key="index"
            >
              <td
                class="relative whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                <div class="flex items-center">
                  <input
                    type="text"
                    name="kode_barang"
                    class="block w-full border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                    placeholder="Kode barang"
                    v-model="orderDetail.search"
                    v-on:change="handleSearchProduct(index)"
                  />
                  <button
                    type="button"
                    @click="handleOpenStockModal(index)"
                    class="absolute inset-y-0 top-0 right-0 flex cursor-pointer items-center pl-3"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5 text-gray-500 hover:text-gray-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ orderDetail.product.name }}
              </td>
              <td
                v-if="columns.includes('product_type')"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                <base-select fit v-if="orderDetail.product.id !== null" :shadow="false" :options="[
                  {
                    key: 'reguler',
                    value: 'reguler',
                    label: 'Reguler'
                  },
                  {
                    key: 'free',
                    value: 'free',
                    label: 'Promo'
                  }
                ]" v-model="orderDetail.type" v-on:change="onChangeType(index)" />
              </td>
              <td
                :class="{
                  'text-red-600': orderDetail.stock < 1,
                }"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                {{ orderDetail.product.stock }}
              </td>
              <td
                v-if="columns.includes('product_orderable')"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  orderDetail.product.status ? 'OPEN' : 'CLOSE'
                }}</span>
              </td>
              <td
                v-if="columns.includes('product_is_point')"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  orderDetail.product.isPoint ? 'Point' : 'Non Point'
                }}</span>
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                :class="{
                  'bg-gray-300':
                    orderDetail.product.status !== null &&
                    !orderDetail.product.status,
                }"
              >
                <input
                  v-if="orderDetail.product.id !== null"
                  placeholder="Jumlah"
                  ref="jumlah_barang"
                  v-model="orderDetail.qty"
                  :disabled="
                    orderDetail.product.status !== null &&
                    !orderDetail.product.status
                  "
                  @change="handleChangeQty(index)"
                  type="text"
                  name="jumlah_barang"
                  class="block w-full border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  :class="{
                    'bg-gray-300':
                      orderDetail.product.status !== null &&
                      !orderDetail.product.status,
                  }"
                />
              </td>
              <td
                v-if="columns.includes('product_point')"
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  orderDetail.product.pointValue | toCurrency
                }}</span>
              </td>
              <td
                v-if="columns.includes('total_point')"
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  (orderDetail.product.pointValue * orderDetail.qty)
                    | toCurrency
                }}</span>
              </td>
              <td
                v-if="columns.includes('product_bonus')"
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  orderDetail.product.bonusValue | toCurrency
                }}</span>
              </td>
              <td
                v-if="columns.includes('total_bonus')"
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  (orderDetail.product.bonusValue * orderDetail.qty)
                    | toCurrency
                }}</span>
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  orderDetail.product.price | toCurrency
                }}</span>
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  (orderDetail.product.price * orderDetail.qty) | toCurrency
                }}</span>
              </td>
              <td
                v-if="columns.includes('total_weight')"
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                <span v-if="orderDetail.product.id !== null">{{
                  (orderDetail.product.weight * orderDetail.qty) | toCurrency
                }}</span>
              </td>
              <td
                v-if="orderDetail.product.code"
                class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                <div class="flex justify-center">
                  <svg
                    @click="handleRemoveProduct(index)"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:tfoot>
          <slot name="tfoot">
            <tfoot class="w-full bg-gray-50">
              <tr
                class="whitespace-nowrap px-6 py-4 pb-0 align-top text-sm font-medium text-gray-500"
              >
                <td class="space-y-4 p-4" :colspan="3 + columns.length">
                  <p
                    v-if="grandTotalPrice < minGrandTotal"
                    class="text-xs font-light italic text-red-600"
                  >
                    Total harga harus diatas
                    {{ minGrandTotal | toCurrency }} supaya dapat di simpan
                  </p>
                  <p
                    v-if="
                      withCashbackWarning &&
                      createOrderPayload.order_shipment === 'pickup' &&
                      canHaveCashback(destinationOffice.code) &&
                      cashbackRemainder
                    "
                    class="text-xs font-light italic text-red-600"
                  >
                    Kurang {{ cashbackRemainder | toCurrency }} lagi supaya
                    dapat cashback
                  </p>
                </td>
                <td
                  class="px-6 py-4 text-right text-sm font-semibold text-gray-900"
                >
                  Total
                </td>
                <td
                  class="px-6 py-4 text-right text-sm font-semibold text-gray-900"
                >
                  {{ grandTotalPrice | toCurrency }}
                </td>
                <td
                  class="px-6 py-4 text-right text-sm font-semibold text-gray-900"
                >
                  {{ grandTotalWeight | toFixed }} Kg
                </td>
                <td></td>
              </tr>
            </tfoot>
          </slot>
        </template>
      </Datatable>
      <slot name="footer"> </slot>
    </div>

    <StockModal
      :visible="visibleStockModal"
      :params="{
        warehouseId: this.originWarehouse.id,
        sellerOfficeCode: this.originOffice.code,
        buyerOfficeCode: this.destinationOffice.code,
        areaCode: this.area.code,
        buyerTypeCode: this.buyerType.code,
      }"
      @close="visibleStockModal = false"
      :columns="stockModalColumns"
      v-on:change="handleChangeStock"
    />
    <ProductModal
      @change="handleChangeProduct"
      :visible="visibleProductModal"
      @close="() => (visibleProductModal = false)"
    />
    <loading v-if="loading.visible" :text="loading.text" />
  </div>
</template>

<script>
import Datatable from '@/components/base/Datatable';
import StockModal from './StockModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { cashbackMixin } from '@/mixins/office-category/cashback.mixin';
import ProductModal from '@/components/order/create/ProductModal.vue';

export default {
  components: { Datatable, StockModal, ProductModal },
  mixins: [cashbackMixin],
  props: {
    viewAs: {
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    stockModalColumns: {
      type: Array,
      default: () => [],
    },
    originOfficeType: {
      type: String,
      default: 'stockist',
    },
    createOrderPayload: Object,
    originWarehouse: {
      type: Object,
      required: true,
    },
    originOffice: {
      type: Object,
      required: true,
    },
    destinationWarehouse: {
      type: Object,
      required: true,
    },
    destinationOffice: {
      type: Object,
      required: true,
    },
    buyerType: {
      type: Object,
      required: true,
    },
    area: {
      type: Object,
      required: true,
    },
    orderId: String,
    minGrandTotal: Number,
    color: {
      type: String,
      default: 'gray',
    },
    withCashbackWarning: {
      type: Boolean,
      default: false,
    },
    originOfficeCategoryType: String,
  },
  emit: ['input', 'created', 'order-updated'],
  data() {
    return {
      visibleProductModal: false,
      visibleStockModal: false,
      currentIndex: null,
      loading: {
        visible: false,
        text: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      getWarehouseStock: 'warehouses/getWarehouseStock',
      getOffices: 'offices/getOffices',
      getOrder: 'orders/getOrder',
    }),
    orderDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    grandTotalPrice() {
      return this.orderDetails.reduce(
        (val, orderDetail) => val + orderDetail.qty * orderDetail.product.price,
        0
      );
    },
    grandTotalWeight() {
      return this.orderDetails.reduce(
        (val, orderDetail) =>
          val + orderDetail.qty * orderDetail.product.weight,
        0
      );
    },
    cashbackRemainder() {
      return Math.max(
        this.getCashbackMinOrder(
          this.originOfficeCategoryType,
          this.area.code
        ) - this.grandTotalPrice,
        0
      );
    },
    cashback() {
      if (!this.withCashbackWarning) {
        return 0
      }

      if (this.createOrderPayload.order_shipment !== 'pickup') {
        return 0;
      }

      return this.getCashbackAmount(
        this.originOfficeCategoryType,
        this.area.code,
        this.grandTotalPrice
      );
    },
    colors() {
      const theadColors = {
        gray: 'bg-gray-50',
        blue: 'bg-blue-600',
      };
      const thColors = {
        blue: 'text-blue-100',
        gray: 'text-gray-500',
      };

      return {
        thead: theadColors[this.color],
        th: thColors[this.color],
      };
    },
    canPurchaseFree() {
      return this.originOfficeCategoryType === 'Supplier'
    }
  },
  methods: {
    ...mapActions({
      fetchProductsByOffice: 'products/fetchProductsByOffice',
      fetchProducts: 'products/fetchProducts',
      fetchWarehouseStock: 'warehouses/fetchWarehouseStock',
      createAlert: 'alert/createAlert',
      postOrderDetail: 'order_details/createOrderDetail',
      patchOrderDetail: 'order_details/updateOrderDetail',
      deleteOrderDetail: 'order_details/deleteOrderDetail',
      createOrder: 'orders/createOrder',
    }),
    openModalProduct(index) {
      this.currentIndex = index;
      this.visibleProductModal = true;
      this.fetchProductsByOffice({
        include: 'product-limits,unit,product-category',
        office_id: this.originOffice.id,
        pageSize: 5,
      });
    },
    handleOpenStockModal(index) {
      if (this.viewAs == 'supplier') {
        this.openModalProduct(index);
        return;
      }
      this.currentIndex = index;
      this.visibleStockModal = true;
    },
    async handleSearchProduct(index) {
      this.currentIndex = index;

      const productCode = this.orderDetails[index].search;

      if (productCode) {
        let stock = null;
        if (this.viewAs == 'supplier') {
          const product = await this.searchProduct({ productCode });
          this.handleChangeProduct(product);
        } else {
          stock = await this.searchStockByProduct({ productCode });
        }

        if (stock) {
          this.handleChangeStock(stock);
        } else {
          this.orderDetails[this.currentIndex].search =
            this.orderDetails[this.currentIndex].product.code;
        }
      }
    },
    async handleChangeProduct(data) {
      this.visibleProductModal = false;
    
      const productType = this.canPurchaseFree && this.checkProductWithTypeExists(data.id, 'reguler') ? 'free' : 'reguler'

      const orderDetail = await this.storeOrderDetail({
        productId: data.id,
        orderDetailId: this.orderDetails[this.currentIndex]?.id,
        qty: 0,
        productType
      });
      if (orderDetail) {
        this.setOrderDetail(this.currentIndex, orderDetail);
        this.currentIndex = null;
      } else {
        this.orderDetails[this.currentIndex].search =
          this.orderDetails[this.currentIndex].product.code;
      }
    },
    async handleChangeStock(stock) {
      const product = this.getSingleIncluded(
        this.getWarehouseStock,
        stock.relationships.product.data.id
      );

      const orderDetail = await this.storeOrderDetail({
        productId: product.id,
        orderDetailId: this.orderDetails[this.currentIndex].id,
        qty: 0,
      });

      if (orderDetail) {
        this.setOrderDetail(this.currentIndex, orderDetail);

        this.visibleStockModal = false;
        this.currentIndex = null;

        this.$emit('order-updated')
      } else {
        this.orderDetails[this.currentIndex].search =
          this.orderDetails[this.currentIndex].product.code;
      }
    },
    async handleChangeQty(index) {
      if (
        this.orderDetails[index].qty < 0 ||
        isNaN(this.orderDetails[index].qty)
      ) {
        this.orderDetails[index].qty = 0;

        this.createAlert({
          data: 'Jumlah harus bilangan bulat dan tidak boleh kurang dari 0',
          status: 'error',
        });
      } else {
        const orderDetail = await this.storeOrderDetail({
          orderDetailId: this.orderDetails[index].id,
          productId: this.orderDetails[index].product.id,
          qty: this.orderDetails[index].qty,
        });

        if (!orderDetail) {
          this.orderDetails[index].qty = this.orderDetails[index].product.stock;
        } else {
          const isLastRow = this.orderDetails.length - 1 === index;

          if (isLastRow) {
            this.pushEmptyProduct();
          }

          this.$emit('order-updated')
        }
      }
    },
    async onChangeType(index) {
      this.startLoading('Menyimpan Produk');

      const orderDetailId = this.orderDetails[index].id

      const res = await this.patchOrderDetail({
        id: orderDetailId,
        payload: {
          data: {
            id: orderDetailId,
            type: 'order-details',
            attributes: {
              product_type: this.orderDetails[index].type,
            }
          },
        },
      });

      if (res) {
        this.orderDetails[index].product.price = res.data.data.attributes.product_price
      } else {
        this.orderDetails[index].type = this.orderDetails[index].type === 'free' ? 'reguler' : 'free'
      }

      this.stopLoading();
    },
    async handleRemoveProduct(index) {
      this.startLoading('Menghapus Barang');

      const orderDetailId = this.orderDetails[index].id;

      await this.deleteOrderDetail({ id: orderDetailId });

      this.orderDetails.splice(index, 1);

      if (!this.orderDetails.length) {
        this.pushEmptyProduct();
      }

      this.$emit('order-updated')

      this.stopLoading();
    },
    pushEmptyProduct() {
      this.orderDetails.push({
        id: null,
        search: null,
        product: {
          id: null,
          code: null,
          name: null,
          stock: null,
          status: null,
          isPoint: null,
          pointValue: null,
          bonusValue: null,
          price: null,
          weight: null,
        },
        qty: null,
        type: 'reguler',
      });
    },
    setOrderDetail(index, orderDetail) {
      this.orderDetails[index].id = orderDetail.id;
      this.orderDetails[index].product.id = orderDetail.attributes.product_id;
      this.orderDetails[index].search = orderDetail.attributes.product_code;
      this.orderDetails[index].product.code =
        orderDetail.attributes.product_code;
      this.orderDetails[index].product.name =
        orderDetail.attributes.product_name;
      this.orderDetails[index].product.stock =
        orderDetail.attributes.current_stock +
        orderDetail.attributes.product_qty;
      this.orderDetails[index].product.status = true;
      this.orderDetails[index].type = orderDetail.attributes.product_type;
      (this.orderDetails[index].product.isPoint =
        !orderDetail.attributes.point_value),
        (this.orderDetails[index].product.pointValue =
          orderDetail.attributes.point_value),
        (this.orderDetails[index].product.bonusValue =
          orderDetail.attributes.bonus_value),
        (this.orderDetails[index].product.price =
          orderDetail.attributes.product_price),
        (this.orderDetails[index].product.weight =
          orderDetail.attributes.product_weight);
      this.orderDetails[index].qty = orderDetail.attributes.product_qty || null;

      setTimeout(() => {
        this.$refs.jumlah_barang[index].focus();
      }, 0);
    },
    startLoading(text) {
      this.loading.text = text;
      this.loading.visible = true;
    },
    stopLoading() {
      this.loading.visible = false;
    },
    async searchProduct({ productCode }) {
      this.startLoading('Mencari Barang');

      const res = await this.fetchProducts({
        include: 'product-limits,unit,product-category',
        'filter[code]': productCode,
      });

      this.stopLoading();
      if (res) {
        return res.data.data[0];
      } else {
        this.createAlert({
          data: `Produk Tidak Ditemukan`,
          status: 'error',
        });
      }
    },
    async searchStockByProduct({ productCode }) {
      this.startLoading('Mencari Barang');

      const res = await this.fetchWarehouseStock({
        warehouseId: this.originWarehouse.id,
        sellerOfficeCode: this.originOffice.code,
        buyerOfficeCode: this.destinationOffice.code,
        areaCode: this.area.code,
        buyerTypeCode: this.buyerType.code,
        productCode: productCode,
        pageNumber: 1,
        pageSize: 1,
        'filter[has_stock_real]': true,
      });

      this.stopLoading();

      if (res) {
        return res.data.data[0];
      } else {
        this.createAlert({
          data: `Produk Tidak Ditemukan`,
          status: 'error',
        });
      }
    },
    async storeOrder() {
      const res = await this.createOrder({
        data: {
          type: 'orders',
          attributes: {
            ...this.createOrderPayload,
            origin_warehouse_id: this.originWarehouse.id,
            destination_warehouse_id: this.destinationWarehouse.id,
            origin_office_id: this.originOffice.id,
            destination_office_id: this.destinationOffice.id,
          },
          relationships: {
            'buyer-type': {
              data: {
                type: 'buyer-types',
                id: this.buyerType.id,
              },
            },
            area: {
              data: {
                type: 'areas',
                id: this.area.id,
              },
            },
          },
        },
      });

      if (res) {
        this.$emit('order-created', res.data.data);

        return res.data.data;
      }
    },
    async storeOrderDetail({ orderDetailId, productId, qty, productType = 'reguler' }) {
      if (orderDetailId) {
        return await this.updateOrderDetail({ orderDetailId, productId, qty });
      } else {
        return await this.createOrderDetail({ productId, productType });
      }
    },
    async createOrderDetail({ productId, productType = 'reguler' }) {
      this.startLoading('Menambahkan Produk');

      if (!this.orderId) {
        const order = await this.storeOrder();

        if (order) {
          const res = await this.postOrderDetail({
            payload: {
              data: {
                type: 'order-details',
                attributes: {
                  product_qty: 0,
                  product_type: productType
                },
                relationships: {
                  order: {
                    data: {
                      type: 'orders',
                      id: this.orderId,
                    },
                  },
                  product: {
                    data: {
                      type: 'products',
                      id: productId,
                    },
                  },
                },
              },
            },
          });

          this.stopLoading();

          if (res) {
            this.$emit('created');

            return res.data.data;
          }
        } else {
          this.stopLoading();
        }
      } else {
        const res = await this.postOrderDetail({
          payload: {
            data: {
              type: 'order-details',
              attributes: {
                product_qty: 0,
                product_type: productType
              },
              relationships: {
                order: {
                  data: {
                    type: 'orders',
                    id: this.orderId,
                  },
                },
                product: {
                  data: {
                    type: 'products',
                    id: productId,
                  },
                },
              },
            },
          },
        });

        this.stopLoading();

        if (res) {
          return res.data.data;
        }
      }
    },
    async updateOrderDetail({ orderDetailId, productId, qty }) {
      this.startLoading('Menyimpan Produk');

      const res = await this.patchOrderDetail({
        id: orderDetailId,
        payload: {
          data: {
            id: orderDetailId,
            type: 'order-details',
            attributes: {
              product_qty: parseInt(qty),
            },
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.orderId,
                },
              },
              product: {
                data: {
                  type: 'products',
                  id: productId,
                },
              },
            },
          },
        },
      });

      this.stopLoading();

      if (res) {
        this.notifyCashback();

        return res.data.data;
      }
    },
    notifyCashback() {
      const isShipment =
        this.getOrder.data.attributes.order_shipment != 'delivery';

      if (this.cashback && isShipment) {
        this.createAlert({
          data: `Berhasil mendapatkan cashback ${this.$options.filters.toCurrency(
            this.cashback
          )}`,
        });
      }
    },
    checkProductWithTypeExists(productId, type) {
      return this.orderDetails.some(orderDetail => orderDetail.product.id === productId && orderDetail.type === type)
    }
  },
};
</script>
