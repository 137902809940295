<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-5xl"
      @opened="onOpened"
      @close="$emit('close')"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar Gudang
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Daftar Gudang Yang Telah Dibuat
              </p>
            </div>
          </div>
        </div>
        <div class="space-y-4 border-t border-gray-200 pt-6">
          <div class="flex justify-end gap-x-2">
            <base-input
              placeholder="Cari Kode atau Nama"
              type="search"
              :shadow="false"
              debounce
              v-model="filter.search"
              @native-input="loadWarehouses"
            />
          </div>
          <datatable
            :columns="columns"
            :scroll-x="false"
            :total="warehouses.meta.page.total"
            :perPage="warehouses.meta.page.perPage"
            :currentPage="warehouses.meta.page.currentPage"
            @pagechanged="onPagechanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="warehouse in warehouses.data"
                :key="warehouse.id"
                :class="[classes.tr, 'cursor-pointer hover:bg-green-100']"
                @click="onClickWarehouse(warehouse)"
              >
                <td :class="[classes.td]">
                  <span class="font-bold text-gray-900">
                    {{ warehouse.attributes.code }}
                  </span>
                </td>
                <td :class="[classes.td]">
                  {{ warehouse.attributes.name }}
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseModal,
  },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    requestParams: null,
  },
  emits: ['close', 'click-warehouse'],
  data() {
    return {
      warehouses: {
        data: [],
        meta: {
          page: {},
        },
      },
      loading: false,
      filter: {
        search: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    columns() {
      return [
        {
          id: 'code',
          name: 'Kode Gudang',
        },
        {
          id: 'name',
          name: 'Nama Gudang',
        },
      ];
    },
  },
  methods: {
    async loadWarehouses(params) {
      this.loading = true;

      const [res, error] = await this.request(`/api/v1/warehouses`, {
        params: {
          ...this.requestParams,
          'page[size]': 5,
          include: 'area',
          'fields[warehouses]': 'code,name',
          'filter[search]': this.filter.search,
          'filter[warehouse_type]': 'pusat,kantor_pelayanan',
          ...params,
        },
      });

      if (!error) {
        this.warehouses = res;
      }

      this.loading = false;
    },
    onClickWarehouse(warehouse) {
      this.$emit('click-warehouse', warehouse, this.warehouses);
    },
    onOpened() {
      this.filter.search = null;

      this.loadWarehouses();
    },
    onPagechanged(page) {
      this.loadWarehouses({
        'page[number]': page,
      });
    },
  },
};
</script>
