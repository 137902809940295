<template>
  <div>
    <base-wrapper class="space-y-6">
      <create-order-form
        :valid="order.valid"
        :with-destination-office="false"
        :with-origin-warehouse="false"
        with-origin-office
        :with-origin-office-area="false"
        :with-options="false"
        v-model="order"
      />
      <create-order-detail-form
        v-if="canCreateOrderDetail"
        type="product"
        :disabled="order.valid"
        :order-id="order.id"
        :destination-office="{ id: me.office_id, code: me.office_code }"
        :origin-office="{
          id: order.originOffice.id,
          code: order.originOffice.code,
        }"
        :buyer-type="{
          id: order.buyerType.id,
          code: order.buyerType.code,
        }"
        :area="{
          id: order.area.id,
          code: order.area.code,
        }"
        :create-order-attributes="{
          order_type: 'purchase',
          origin_warehouse_id: order.originOffice.currentWarehouse.id,
          destination_warehouse_id: me.current_warehouse,
        }"
        v-model="orderDetails"
        @order-created="onOrderCreated"
      />
      <div class="flex justify-end gap-x-2">
        <base-button
          v-if="!order.valid"
          :disabled="!canValidate"
          @click="onValidate"
          >Validasi</base-button
        >
        <base-button :to="{ name: 'pembelian' }" color="white"
          >Kembali</base-button
        >
      </div>
    </base-wrapper>
    <view-order-modal
      :visible="viewOrderModalVisible"
      :order-id="order.id"
      :with-payment="false"
      label="Pembelian"
      @validated="$router.push({ name: 'pembelian' })"
      @close="$router.push({ name: 'pembelian' })"
    />
    <validate-order-confirmation
      :order-id="order.id"
      :visible="confirmValidateOrderVisible"
      @close="confirmValidateOrderVisible = false"
      @confirmed="onValidateConfirmed"
    />
    <loading v-if="loading" />
  </div>
</template>

<script>
import CreateOrderForm from '@/components/order/create-order-form.vue';
import CreateOrderDetailForm from '@/components/order/create-order-detail-form.vue';
import { mapActions, mapGetters } from 'vuex';
import { requestMixin } from '@/mixins/request/request';
import ViewOrderModal from '@/components/order/view-order-modal.vue';
import ValidateOrderConfirmation from '@/components/order/validate-order-confirmation.vue';

export default {
  mixins: [requestMixin],
  components: {
    CreateOrderForm,
    CreateOrderDetailForm,
    ViewOrderModal,
    ValidateOrderConfirmation,
  },
  data() {
    return {
      confirmValidateOrderVisible: false,
      loadingBuyerType: false,
      loadingArea: false,
      loadingOrder: false,
      order: {
        id: null,
        area: {
          id: null,
          code: null,
        },
        buyerType: {
          id: null,
          code: null,
        },
        date: new Date(),
        originOfficeType: 'supplier',
        originOffice: {
          id: null,
          code: null,
          searchCode: null,
          originalCode: null,
          name: null,
          currentWarehouse: {
            id: null,
            code: null,
          },
        },
        valid: false,
      },
      orderDetails: [
        {
          id: null,
          productCode: null,
          originalProductCode: null,
          productName: null,
          stock: null,
          qty: null,
          originalQty: null,
          price: null,
          totalPrice: null,
        },
      ],
      viewOrderModalVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    canCreateOrderDetail() {
      return this.order.originOffice.id;
    },
    canValidate() {
      return this.order.originOffice.id && this.orderDetails.length;
    },
    loading() {
      return (
        this.loadingBuyerType ||
        this.loadingOrder ||
        this.loadingArea
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadBuyerType() {
      this.loadingBuyerType = true;

      const [res, error] = await this.request('/api/v1/buyer-types', {
        params: {
          'filter[code]': 0,
          'fields[buyer-types]': 'code',
        },
      });

      if (!error) {
        const buyerType = res.data[0];

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;
      }

      this.loadingBuyerType = false;
    },
    async loadArea() {
      this.loadingArea = true;

      const [res, error] = await this.request(`/api/v1/offices/${this.me.office_id}/area`, {
        params: {
          'fields[areas]': 'code'
        }
      });

      if (!error) {
        const area = res.data;

        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;
      }

      this.loadingArea = false;
    },
    async loadOrder() {
      this.loadingOrder = true;

      this.order.id = this.$route.params.id;

      const [res, error] = await this.request(
        `/api/v1/orders/${this.order.id}`,
        {
          params: {
            include:
              'area,buyer-type,origin-office,origin-warehouse,order-details',
            'fields[orders]':
              'createdAt,is_completed,is_valid_for_packing,area,buyer-type,origin-office,origin-warehouse,order-details',
            'fields[areas]': 'code',
            'fields[buyer-types]': 'code',
            'fields[offices]':
              'code,name',
            'fields[warehouses]': 'code,name',
            'fields[order-details]':
              'product_code,product_name,current_stock,product_qty,product_price,total_price',
          },
        }
      );

      if (!error && res.data) {
        const order = res;

        const area = this.getSingleIncluded(
          res,
          order.data.relationships.area.data.id
        );
        const buyerType = this.getSingleIncluded(
          res,
          order.data.relationships['buyer-type'].data.id
        );
        const originOffice = this.getSingleIncluded(
          res,
          order.data.relationships['origin-office'].data.id
        );
        const originWarehouse = this.getSingleIncluded(
          res,
          order.data.relationships['origin-warehouse'].data.id
        );
        const orderDetails = this.getIncludedByType(res, 'order-details');

        this.order.buyerType.id = buyerType.id;
        this.order.buyerType.code = buyerType.attributes.code;

        this.order.date = order.data.attributes.createdAt
        this.order.originOffice.id = originOffice.id
        this.order.originOffice.code = originOffice.attributes.code;
        this.order.originOffice.searchCode = originOffice.attributes.code;
        this.order.originOffice.originalCode =
          this.order.originOffice.searchCode;
        this.order.originOffice.name = originOffice.attributes.name;

        this.order.originOffice.currentWarehouse.id =
          originWarehouse.id;
        this.order.originOffice.currentWarehouse.code =
          originWarehouse.attributes.code;
          
        this.order.area.id = area.id;
        this.order.area.code = area.attributes.code;

        this.order.valid =
          order.data.attributes.is_completed ||
          order.data.attributes.is_valid_for_packing;

        this.orderDetails = orderDetails.map((orderDetail) => ({
          id: orderDetail.id,
          productCode: orderDetail.attributes.product_code,
          originalProductCode: orderDetail.attributes.product_code,
          productName: orderDetail.attributes.product_name,
          stock: orderDetail.attributes.current_stock,
          qty: orderDetail.attributes.product_qty || null,
          originalQty: orderDetail.attributes.product_qty || null,
          price: orderDetail.attributes.product_price,
          totalPrice: orderDetail.attributes.total_price,
        }));

        if (!this.order.valid) {
          this.orderDetails.push({
            id: null,
            productCode: null,
            originalProductCode: null,
            productName: null,
            stock: null,
            qty: null,
            originalQty: null,
            price: null,
            totalPrice: null,
          });
        }
      }

      this.loadingOrder = false;
    },
    onOrderCreated(order) {
      this.order.id = order.data.id;

      this.$router.push({
        name: 'pembelian.edit',
        params: {
          id: order.data.id,
        },
      });
    },
    async onValidate() {
      this.confirmValidateOrderVisible = true;
    },
    onValidateConfirmed() {
      this.confirmValidateOrderVisible = false;
      this.viewOrderModalVisible = true;
    },
  },
  created() {
    if (this.$route.params.id) {
      this.loadOrder();
    } else {
      this.loadBuyerType()
      this.loadArea()
    }
  },
};
</script>
