<template>
  <base-card title="Barang">
    <datatable :scroll-x="false" :columns="columns" id="daftar_barang">
      <template #tbody="{ classes }">
        <tr
          :class="classes.tr"
          v-for="(orderDetail, index) in orderDetails"
          :key="index"
        >
          <td :class="[classes.td, 'relative']">
            <search-stock-input
              ref="search_stock_input"
              :disabled="disabled"
              :original-search="orderDetail.originalProductCode"
              :type="type"
              :origin-office="originOffice"
              :origin-warehouse="originWarehouse"
              :stock-request-params="{
                'filter[seller_office_code]': originOffice.code,
                'filter[buyer_office_code]': destinationOffice.code,
                'filter[area_code]': area.code,
                'filter[buyer_type_code]': buyerType.code,
              }"
              v-model="orderDetails[index].productCode"
              @update:loading="onUpdateLoadingSearch"
              @change="({ stock, product, closeModal }) => onChangeStock(index, stock, product, closeModal)"
            />
          </td>
          <td v-if="orderDetail.id" :class="[classes.td]">
            {{ orderDetail.productName }}
          </td>
          <td v-if="orderDetail.id" :class="[classes.td, 'text-right']">
            {{ orderDetail.stock | toCurrency }}
          </td>
          <td v-if="orderDetail.id" :class="[classes.td]">
            <input
              ref="order_detail_qty"
              :disabled="disabled"
              type="text"
              class="w-full border-0 p-0 placeholder-gray-200 focus:border-0 focus:ring-0 sm:text-sm"
              placeholder="Jumlah"
              v-model="orderDetails[index].qty"
              @change="onChangeQty(index)"
            />
          </td>
          <td v-if="orderDetail.id" :class="[classes.td, 'text-right']">
            {{ orderDetail.price | toCurrency }}
          </td>
          <td v-if="orderDetail.id" :class="[classes.td, 'text-right']">
            {{ orderDetail.totalPrice | toCurrency }}
          </td>
          <td v-if="orderDetail.id" :class="[classes.td, 'text-center']">
            <svg
              v-if="!disabled"
              @click="onRemove(index)"
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto h-4 w-4 cursor-pointer text-red-300 hover:text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </td>
        </tr>
      </template>
      <template #tfoot="{ classes }">
        <tr :class="[classes.tr, 'bg-gray-50']">
          <td :class="classes.td" colspan="3">
            <p
              v-if="totalPrice < minSpend"
              class="text-xs font-light italic text-red-600"
            >
              Total harga harus diatas {{ minSpend | toCurrency }} supaya dapat
              disimpan
            </p>
          </td>
          <td :class="[classes.td, 'font-semibold']">Total</td>
          <td :class="[classes.td, 'text-right font-semibold']">
            {{ totalPrice | toCurrency }}
          </td>
          <td colspan="2"></td>
        </tr>
      </template>
    </datatable>
    
    <loading v-if="loading" />
  </base-card>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';
import SearchStockInput from '@/components/stock/search-stock-input.vue';

/*
originOffice: { id, code }
destinationOffice: { id, code }
originWarehouse: { id, code }
buyerType: { id, code }
area: { id, code },
orderDetails[n]: {
  id: null,
  productCode: null,
  originalProductCode: null,
  productName: null,
  stock: null,
  qty: null,
  originalQty: null,
  price: null,
  totalPrice: null
}
*/
export default {
  mixins: [requestMixin],
  components: {
    SearchStockInput
  },
  props: {
    value: Array,
    originOffice: {
      type: Object,
      required: true,
    },
    destinationOffice: {
      type: Object,
      required: true,
    },
    originWarehouse: Object,
    createOrderAttributes: Object,
    buyerType: {
      type: Object,
      required: true,
    },
    area: {
      type: Object,
      required: true,
    },
    orderId: String,
    minSpend: Number,
    disabled: Boolean,
    type: {
      type: String,
      default: 'stock',
    },
    customStoreOrderPath: String,
    customStoreOrderDetailPath: String
  },
  emits: ['input', 'order-created'],
  data() {
    return {
      loadingSearch: false,
      loadingStoreOrder: false,
      loadingStoreOrderDetail: false
    };
  },
  computed: {
    orderDetails: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    columns() {
      return [
        { id: 'code', name: 'Kode Barang' },
        { id: 'name', name: 'Nama Barang' },
        { id: 'stock', name: 'Stok', theadClass: 'text-right' },
        { id: 'qty', name: 'Jumlah' },
        { id: 'price', name: 'Harga', theadClass: 'text-right' },
        { id: 'total_price', name: 'Total Harga', theadClass: 'text-right' },
        { id: 'action', name: 'Aksi', theadClass: 'text-center' },
      ];
    },
    loading() {
      return (
        this.loadingSearch ||
        this.loadingStoreOrder ||
        this.loadingStoreOrderDetail
      );
    },
    totalPrice() {
      return this.orderDetails.reduce(
        (total, orderDetail) => total + orderDetail.totalPrice,
        0
      );
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async createOrderDetail(index, productId) {
      if (!this.orderId) {
        const [, err] = await this.storeOrder();

        if (err) {
          return [null, err];
        }
      }

      const [res, err] = await this.storeOrderDetail(productId);

      if (!err) {
        const orderDetail = res.data;

        this.orderDetails[index].id = orderDetail.id;
        this.orderDetails[index].productCode =
          orderDetail.attributes.product_code;
        this.orderDetails[index].originalProductCode =
          orderDetail.attributes.product_code;
        this.orderDetails[index].productName =
          orderDetail.attributes.product_name;
        this.orderDetails[index].stock = orderDetail.attributes.current_stock;
        this.orderDetails[index].qty =
          orderDetail.attributes.product_qty || null;
        this.orderDetails[index].originalQty =
          orderDetail.attributes.product_qty || null;
        this.orderDetails[index].price = orderDetail.attributes.product_price;
        this.orderDetails[index].totalPrice =
          orderDetail.attributes.total_price;

        this.$nextTick(() => {
          this.$refs.order_detail_qty[index].focus();
        });
      }

      return [res, err];
    },
    async onRemove(index) {
      this.loadingStoreOrderDetail = true;

      const orderDetail = this.orderDetails[index];

      const [, err] = await this.request(
        this.customStoreOrderDetailPath ?? `/api/v1/order-details/${orderDetail.id}`,
        {
          method: 'delete',
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.orderDetails.splice(index, 1);

        if (
          !this.orderDetails.filter((orderDetail) => !orderDetail.id).length
        ) {
          this.pushEmptyOrderDetail();
        }
      }

      this.loadingStoreOrderDetail = false;
    },
    async onChangeStock(index, stock, product, closeModal) {
      const [, err] = this.orderDetails[index].id
        ? await this.updateOrderDetailProduct(index, product.id)
        : await this.createOrderDetail(index, product.id);

      if (!err && closeModal) {
        closeModal()
      } else {
        this.orderDetails[index].productCode =
          this.orderDetails[index].originalProductCode;
      }
    },
    async onChangeQty(index) {
      this.loadingStoreOrderDetail = true;

      const orderDetail = this.orderDetails[index];

      const [res, err] = await this.request(
        this.customStoreOrderDetailPath ?? `/api/v1/order-details/${orderDetail.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'order-details',
              id: orderDetail.id,
              attributes: {
                product_qty: +orderDetail.qty,
              },
            },
          },
        }
      );

      if (err) {
        this.orderDetails[index].qty = this.orderDetails[index].originalQty;

        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.orderDetails[index].originalQty = this.orderDetails[index].qty;
        this.orderDetails[index].stock = res.data.attributes.current_stock;
        this.orderDetails[index].totalPrice = res.data.attributes.total_price;

        if (index === this.orderDetails.length - 1) {
          this.pushEmptyOrderDetail();

          this.$nextTick(() => {
            this.$refs.search_stock_input[index + 1].$refs.order_detail_code.focus();
          });
        }
      }

      this.loadingStoreOrderDetail = false;
    },
    onUpdateLoadingSearch(value) {
      this.loadingSearch = value
    },
    pushEmptyOrderDetail() {
      this.orderDetails.push({
        id: null,
        productCode: null,
        originalProductCode: null,
        productName: null,
        stock: null,
        qty: null,
        originalQty: null,
        price: null,
        totalPrice: null,
      });
    },
    async storeOrder() {
      this.loadingStoreOrder = true;

      const [res, err] = await this.request(this.customStoreOrderPath ?? '/api/v1/orders', {
        method: 'post',
        data: {
          data: {
            type: 'orders',
            attributes: {
              origin_office_id: this.originOffice.id,
              destination_office_id: this.destinationOffice.id,
              ...(this.originWarehouse
                ? { origin_warehouse_id: this.originWarehouse.id }
                : {}),
              ...this.createOrderAttributes,
            },
            relationships: {
              'buyer-type': {
                data: {
                  type: 'buyer-types',
                  id: this.buyerType.id,
                },
              },
              area: {
                data: {
                  type: 'areas',
                  id: this.area.id,
                },
              },
            },
          },
        },
      });

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('order-created', res);
      }

      this.loadingStoreOrder = false;

      return [res, err];
    },
    async storeOrderDetail(productId) {
      this.loadingStoreOrderDetail = true;

      const [res, err] = await this.request(this.customStoreOrderDetailPath ?? '/api/v1/order-details', {
        method: 'post',
        data: {
          data: {
            type: 'order-details',
            attributes: {
              product_qty: 0,
            },
            relationships: {
              order: {
                data: {
                  type: 'orders',
                  id: this.orderId,
                },
              },
              product: {
                data: {
                  type: 'products',
                  id: productId,
                },
              },
            },
          },
        },
      });

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loadingStoreOrderDetail = false;

      return [res, err];
    },
    async patchOrderDetailProduct(orderDetail, productId) {
      this.loadingStoreOrderDetail = true;

      const [res, err] = await this.request(
        this.customStoreOrderDetailPath ?? `/api/v1/order-details/${orderDetail.id}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'order-details',
              id: orderDetail.id,
              attributes: {
                product_qty: 0,
              },
              relationships: {
                product: {
                  data: {
                    type: 'products',
                    id: productId,
                  },
                },
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loadingStoreOrderDetail = false;

      return [res, err];
    },
    async updateOrderDetailProduct(index, productId) {
      const orderDetail = this.orderDetails[index];

      const [res, err] = await this.patchOrderDetailProduct(
        orderDetail,
        productId
      );

      if (!err) {
        const newOrderDetail = res.data;

        this.orderDetails[index].productCode =
          newOrderDetail.attributes.product_code;
        this.orderDetails[index].originalProductCode =
          newOrderDetail.attributes.product_code;
        this.orderDetails[index].productName =
          newOrderDetail.attributes.product_name;
        this.orderDetails[index].stock =
          newOrderDetail.attributes.current_stock;
        this.orderDetails[index].qty =
          newOrderDetail.attributes.product_qty || null;
        this.orderDetails[index].originalQty =
          newOrderDetail.attributes.product_qty || null;
        this.orderDetails[index].price =
          newOrderDetail.attributes.product_price;
        this.orderDetails[index].totalPrice =
          newOrderDetail.attributes.total_price;

        this.$nextTick(() => {
          this.$refs.order_detail_qty[index].focus();
        });
      }

      return [res, err];
    },
  },
};
</script>
