<template>
  <div>
    <base-confirm
      v-if="visible"
      @close="$emit('close')"
      @confirmed="onConfirmed"
      size="max-w-xl"
      message="Faktur akan divalidasi ke gudang. Apakah Anda yakin data yang dibuat sudah benar?"
      type="success"
      visible
    />
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseConfirm from '@/components/base/BaseConfirm.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  components: { BaseConfirm },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    orderId: String,
  },
  emits: ['close', 'confirmed'],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onConfirmed() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/orders/${this.orderId}/-actions/validateOrder`,
        {
          method: 'patch',
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      } else {
        this.$emit('confirmed');
      }

      this.loading = false;
    },
  },
};
</script>
