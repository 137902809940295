<template>
  <base-wrapper>
    <div class="space-y-4">
      <div class="flex items-center justify-end gap-x-2">
        <base-input
          v-model="filter.search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          @native-input="loadOrders"
          type="search"
        />
        <base-select
          fit
          :shadow="false"
          :options="[
            { key: 'all', label: 'Status', value: null },
            { key: 'not_received', label: 'Belum Diterima', value: false },
            { key: 'received', label: 'Diterima', value: true },
          ]"
          v-model="filter.is_received"
          @change="loadOrders"
        />
        <base-button :to="{ name: 'pembelian.tambah' }"
          >Tambah Restock</base-button
        >
      </div>
      <div>
        <datatable
          :columns="columns"
          :data="orders.data"
          :scroll-x="false"
          :total="orders.meta.page.total"
          :perPage="orders.meta.page.perPage"
          :currentPage="orders.meta.page.currentPage"
          :meta="orders.meta"
          cursor
          @pagechanged="onPageChanged"
        >
          <template v-slot:tbody="{ classes }">
            <tr
              v-for="(order, index) in orders.data"
              :key="order.id"
              :class="[
                classes.tr,
                index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                'cursor-pointer bg-white hover:bg-green-100',
              ]"
              @click="
                onClickRow(
                  getSingleIncluded(orders, order.relationships.order.data.id)
                )
              "
            >
              <td :class="[classes.td]">
                <p>
                  <span class="font-bold text-gray-900">{{
                    order.attributes.origin_code
                  }}</span>
                </p>
                <span class="text-xs text-gray-400">{{
                  order.attributes.updatedAt | formatDate
                }}</span>
              </td>
              <td :class="classes.td">
                {{ order.attributes.origin_code }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.item_count | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.final_price | toCurrency
                }}
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.is_valid_for_payment
                      ? 'green'
                      : 'yellow'
                  "
                  >{{
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.is_valid_for_payment
                      ? 'Valid'
                      : 'Draft'
                  }}</base-badge
                >
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge
                  :color="
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.is_completed
                      ? 'green'
                      : 'red'
                  "
                  >{{
                    getSingleIncluded(orders, order.relationships.order.data.id)
                      .attributes.is_completed
                      ? 'Selesai'
                      : 'Belum Selesai'
                  }}</base-badge
                >
              </td>
              <td :class="[classes.td, 'text-center']">
                <base-badge color="indigo">{{
                  order.attributes.order_status
                }}</base-badge>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>
    <view-order-modal
      :visible="viewOrderModal.visible"
      :order-id="viewOrderModal.orderId"
      :with-payment="false"
      edit-route-name="pembelian.edit"
      label="Pembelian"
      @close="viewOrderModal.visible = false"
      @validated="onValidated"
      @deleted="onDeleted"
    />
    <loading v-if="loading" />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';
import ViewOrderModal from '@/components/order/view-order-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewOrderModal },
  data() {
    return {
      filter: {
        is_received: null,
        search: null,
      },
      orders: {
        data: [],
        meta: {
          page: {
            total: 0,
          },
        },
      },
      loading: false,
      viewOrderModal: {
        visible: false,
        orderId: null,
      },
    };
  },
  computed: {
    columns() {
      return [
        { id: 'code', name: 'Nomor Pembelian' },
        { id: 'origin_office_code', name: 'Kode Supplier' },
        {
          id: 'total_products',
          name: 'Total Produk',
          theadClass: 'text-right',
        },
        { id: 'total_price', name: 'Total Harga', theadClass: 'text-right' },
        {
          id: 'order_status',
          name: 'Status Faktur',
          theadClass: 'text-center',
        },
        {
          id: 'report_status',
          name: 'Status Laporan',
          theadClass: 'text-center',
        },
        { id: 'status', name: 'Status', theadClass: 'text-center' },
      ];
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async loadOrders(params = {}) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          'filter[destination_office_category_id]': 1,
          include: 'order',
          'filter[search]': this.filter.search,
          ...(this.filter.is_received === null
            ? {}
            : this.filter.is_received === true
            ? { 'filter[order_status]': 'Selesai' }
            : { 'filter[not-order_status]': 'Selesai' }),
          'fields[simple-orders]':
            'order_id,order,origin_code,updatedAt,order_status',
          'fields[orders]':
            'item_count,grand_total_weight,final_price,is_valid_for_payment,is_completed',
          ...params,
        },
      });

      if (!error) {
        this.orders = res;
      }

      this.loading = false;
    },
    onClickRow(order) {
      this.viewOrderModal.orderId = order.id;
      this.viewOrderModal.visible = true;
    },
    onPageChanged(page) {
      this.loadOrders({
        ...page,
      });
    },
    onValidated() {
      this.viewOrderModal.visible = false;
      this.loadOrders();
    },
    onDeleted() {
      this.viewOrderModal.visible = false;
      this.loadOrders();
    },
  },
  created() {
    this.loadOrders();
  },
};
</script>
