<template>
  <BaseCard
    title="Tambah Koli"
    description="Tambahkan barang yang akan dikemas"
  >
    <div
      v-for="(packet, packetIndex) in packets"
      :key="packetIndex"
      :id="`koli-${packetIndex}`"
      class="mt-2 mb-5 rounded-lg border border-gray-200 bg-white p-4 sm:p-6 md:p-8"
    >
      <div class="sm:flex-no-wrap mb-8 flex flex-wrap gap-y-3 gap-x-4">
        <div class="flex-grow">
          <div class="field-inset-default">
            <label for="kode_po" class="block text-xs font-bold text-gray-700">
              Nama Koli
            </label>
            <input
              v-model="packet.name"
              type="text"
              name="kode_po"
              id="kode_po"
              class="block border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
              :placeholder="`Contoh: Koli ${packetIndex + 1}`"
            />
          </div>
        </div>
        <div class="sm:col-span-1">
          <button
            @click="onRemovePacket(packetIndex)"
            :disabled="packets.length < 2"
            class="field-inset-default"
            :class="
              packets.length < 2
                ? 'border border-gray-500 bg-gray-100 text-gray-500'
                : 'border border-red-500 bg-red-100 text-red-500'
            "
          >
            <div class="flex items-center justify-center py-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                :class="packets.length < 2 ? 'text-gray-400' : 'text-red-500'"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                />
              </svg>
              <p
                class="ml-1 text-xs"
                :class="packets.length < 2 ? 'text-gray-400' : 'text-red-500'"
              >
                Hapus Koli
              </p>
            </div>
          </button>
        </div>
      </div>
      <Datatable :paginated="false" :footer="false">
        <template v-slot:thead>
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                KODE BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                NAMA BARANG
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                JUMLAH
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
              >
                AKSI
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody v-if="packet.products.length > 0">
            <tr
              class="bg-white"
              v-for="(product, productIndex) in packet.products"
              :key="productIndex"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                {{
                  getOrderDetailOrBonusById(product.orderDetailOrBonusId).attributes
                    .product_code
                }}
                {{ getOrderDetailOrBonusById(product.orderDetailOrBonusId).type === 'order-detail-bonuses' ? '(BONUS)' : '' }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{
                  getOrderDetailOrBonusById(product.orderDetailOrBonusId).attributes
                    .product_name
                }}
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                <input
                  :value="packets[packetIndex].products[productIndex].qty"
                  type="text"
                  name="kode_details"
                  class="block border-0 p-0 placeholder-gray-200 focus:ring-0 sm:text-sm"
                  placeholder="Jumlah..."
                  @blur="
                    (e) => onBlurPacketProductQty(e, packetIndex, productIndex)
                  "
                />
              </td>
              <td
                class="flex justify-center whitespace-nowrap px-6 py-4 text-sm text-gray-500"
              >
                <button
                  @click="onRemovePacketProduct({ packetIndex, productIndex })"
                  type="button"
                >
                  <svg
                    class="cursor-pointer"
                    width="15"
                    height="16"
                    viewBox="0 0 15 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.5 0C6.12123 0 5.77497 0.214002 5.60557 0.552786L4.88197 2H1.5C0.947715 2 0.5 2.44772 0.5 3C0.5 3.55228 0.947716 4 1.5 4L1.5 14C1.5 15.1046 2.39543 16 3.5 16H11.5C12.6046 16 13.5 15.1046 13.5 14V4C14.0523 4 14.5 3.55228 14.5 3C14.5 2.44772 14.0523 2 13.5 2H10.118L9.39443 0.552786C9.22504 0.214002 8.87877 0 8.5 0H6.5ZM4.5 6C4.5 5.44772 4.94772 5 5.5 5C6.05228 5 6.5 5.44772 6.5 6V12C6.5 12.5523 6.05228 13 5.5 13C4.94772 13 4.5 12.5523 4.5 12V6ZM9.5 5C8.94771 5 8.5 5.44772 8.5 6V12C8.5 12.5523 8.94771 13 9.5 13C10.0523 13 10.5 12.5523 10.5 12V6C10.5 5.44772 10.0523 5 9.5 5Z"
                      fill="#EF4444"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <EmptyKoliLabel @click="onAddPacketProduct(packetIndex)" />
          </tbody>
        </template>
      </Datatable>
      <div v-if="packet.products.length > 0" class="mt-4 flex justify-end">
        <button
          @click="onAddPacketProduct(packetIndex)"
          type="button"
          class="bg-white-600 ml-3 rounded-md border border-gray-300 py-2 px-4 text-xs font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
        >
          Tambah Produk
        </button>
      </div>
    </div>
    <div class="flex justify-end space-x-2">
      <base-button @click="onAddPacket" type="button">
        Tambah Koli
      </base-button>
    </div>
  </BaseCard>
</template>

<script>
import Datatable from '@/components/base/Datatable';
import EmptyKoliLabel from '@/components/pengeluaran/EmptyKoliLabel.vue';

export default {
  name: 'CreateStockMovementForm',
  props: {
    packets: {
      type: [Array],
    },
    getOrderDetailOrBonusById: Function,
  },
  components: {
    Datatable,
    EmptyKoliLabel,
  },
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    // Your computed properties here
  },
  methods: {
    onRemovePacketProduct(val) {
      this.$emit('remove-packet-product', val);
    },
    onRemovePacket(val) {
      this.$emit('remove-packet', val);
    },
    onBlurPacketProductQty(e, packetIndex, productIndex) {
      this.$emit('blur-packet-product-qty', { e, packetIndex, productIndex });
    },
    onAddPacketProduct(val) {
      this.$emit('add-packet-product', val);
    },
    onAddPacket(val) {
      this.$emit('add-packet', val);
    },
    // Your component methods here
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
