<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard title="Pengeluaran Barang dari Supplier">
      <div class="flex items-center justify-end space-x-2">
        <base-select
          class="w-auto"
          @change="onReload"
          :options="[
            {
              label: 'Semua',
              value: 'Diproses Gudang Pengeluaran,Persiapan',
            },
            { label: 'Sudah Dikemas', value: 'Persiapan' },
            { label: 'Belum Dikemas', value: 'Diproses Gudang Pengeluaran' },
          ]"
          v-model="filter.order_status"
        />
        <base-search
          placeholder="Cari Kode"
          v-model="filter.search"
          v-on:input="onReloadDebounce"
        />
      </div>
      <div class="my-2 flex sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <Datatable
            id="daftar_order"
            :total="getOrders.meta.page.total"
            :perPage="getOrders.meta.page.perPage"
            :currentPage="getOrders.meta.page.currentPage"
            :meta="getOrders.meta"
            cursor
            @pagechanged="onPageChanged"
          >
            <template v-slot:thead>
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-for="column in [
                      {
                        label: 'Nomor Pembelian',
                      },
                      {
                        label: 'Kode Supplier',
                      },
                      {
                        label: 'Tanggal Pembelian',
                      },
                      {
                        label: 'Total Produk',
                        classes: 'text-right',
                      },
                      {
                        label: 'Total Berat',
                        classes: 'text-right',
                      },
                      {
                        label: 'Total Harga',
                        classes: 'text-right',
                      },
                      {
                        label: 'Status',
                        classes: 'text-center',
                      },
                    ]"
                    scope="col"
                    :key="column.label"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    :class="column.classes"
                  >
                    {{ column.label }}
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <div v-if="isLoading" class="flex items-center text-center">
                <loading></loading>
              </div>
              <tbody v-if="getOrders.data?.length > 0">
                <tr
                  class="cursor-pointer bg-white hover:bg-green-100"
                  v-for="(data, dataIdx) in getOrders.data"
                  :key="data.id"
                  :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @click.prevent="onViewOrder(data)"
                >
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  >
                    <order-code-column :value="data" />
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{ getOrderIncluded(data).attributes.origin_code }}
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      dayjs(getOrderIncluded(data).attributes.updatedAt).format(
                        'll LT'
                      )
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{ getOrderIncluded(data).attributes.item_count }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{ getOrderIncluded(data).attributes.grand_total_weight }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                  >
                    {{
                      (getOrderIncluded(data).attributes.grand_total_price ?? 0)
                        | toCurrency
                    }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                  >
                    <span
                      class="inline-flex items-center rounded-full bg-indigo-100 px-3 py-1 text-sm font-medium text-indigo-800"
                    >
                      {{ getOrderIncluded(data).attributes.order_status }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="bg-white">
                  <td
                    class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    colspan="7"
                  >
                    Data tidak ditemukan
                  </td>
                </tr>
              </tbody>
            </template>
          </Datatable>
        </div>
      </div>
    </BaseCard>

    <!-- Detail Modal -->
    <SupplierPengeluaranModal
      :visible="openDetail"
      @close="onCLose"
      @refresh="onRefresh"
      @detail-stock-movement="onViewStockMovement"
    >
      <template #action="{ order }">
        <div class="flex justify-between space-x-2">
          <div>
            <base-button
              color="danger"
              v-if="!order.attributes.is_valid_for_shipment"
              @click="onRemove(order)"
            >
              <Icon class="h-5 w-5" icon="heroicons:trash-20-solid" />
              Hapus
            </base-button>
          </div>
          <div class="flex gap-x-2">
            <base-button
              v-if="!order.attributes.is_valid_for_shipment"
              @click="onValidate(order)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mr-2 h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                />
              </svg>
              Validasi
            </base-button>
            <base-button color="danger" @click="onCLose" type="button">
              Tutup
            </base-button>
          </div>
        </div>
      </template>
    </SupplierPengeluaranModal>
    <stock-movement-modal
      :visible="openDetailStockMovement"
      title="Detail Pengeluaran Barang"
      description="Data pengeluaran barang dari Supplier"
      :with-dispute="false"
      :with-confirm="false"
      :with-send="false"
      with-batches
      force-display-first-packet
      view-as="origin"
      @close="onCloseViewStockMovement"
      @refresh="onRefreshStockMovement"
    >
      <template #origin-office="{ originOffice }">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Nomor Supplier</dt>
          <dd class="mt-1 text-sm text-gray-900">
            {{ originOffice.attributes.code }}
          </dd>
        </div>
      </template>

      <template #additional-attribute="{ stockMovement }">
        <div class="sm:col-span-2">
          <dt class="text-sm font-bold text-gray-700">Status Validasi</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <base-badge
              :color="
                stockMovement.data.attributes.is_valid_for_shipment
                  ? 'green'
                  : 'yellow'
              "
            >
              {{
                stockMovement.data.attributes.is_valid_for_shipment
                  ? 'Valid'
                  : 'Draft'
              }}</base-badge
            >
          </dd>
        </div>
      </template>
    </stock-movement-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Datatable from '@/components/base/Datatable';
import SupplierPengeluaranModal from '@/components/supplier/SupplierPengeluaranModal';
import BaseSearch from '@/components/base/Search.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseBadge from '@/components/base/BaseBadge.vue';
// import BaseInput from '@/components/base/BaseInput.vue';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import OrderCodeColumn from '@/components/order/order-code-column.vue';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import { orderRead } from '@/mixins/order/order-read.mixin';
import BaseCard from '@/components/base/BaseCard.vue';

export default {
  name: 'Home',
  components: {
    SupplierPengeluaranModal,
    Datatable,
    BaseSearch,
    BaseSelect,
    BaseBadge,
    BaseCard,
    BaseButton,
    OrderCodeColumn,
    StockMovementModal,
  },
  mixins: [orderRead],

  data() {
    return {
      openDetailStockMovement: false,
      openDetail: false,
      filter: {
        order_status: 'Diproses Gudang Pengeluaran,Persiapan',
        search: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      isOrderLoading: 'orders/getLoading',
      isStockMovementLoading: 'stock_movements/getLoading',
    }),
    isLoading() {
      return this.isOrderLoading || this.isStockMovementLoading;
    },
    readOrdersParams() {
      return {
        include: 'order',
        'fields[simple-orders]': 'order,origin_code,destination_code',
        'fields[orders]':
          'origin_code,destination_code,updatedAt,item_count,grand_total_weight,grand_total_price,order_status',
        'page[limit]': 5,
        'pagination[type]': 'cursor',
        'filter[destination_office_category_id]': 1,
        'filter[destination_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'filter[order_status]': this.filter.order_status,
        'filter[not-order_status]': 'Diproses Gudang Pengiriman',
        ...(this.search ? { 'filter[search]': this.filter.search } : {}),
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockMovement: 'stock_movements/fetchStockMovement',
      fetchStockMovements: 'stock_movements/fetchStockMovements',
      updateStockMovement: 'stock_movements/updateStockMovement',
      deleteStockMovement: 'stock_movements/deleteStockMovement',
    }),
    onOrderViewed() {
      if (this.getOrder.data.relationships['stock-movements'].data.length > 0) {
        this.openDetail = !this.openDetail;

        this.fetchStockMovements({
          orderId: this.getOrder.data.id,
          include: 'product-batches',
        });
      } else {
        this.$router.push({
          name: 'gudang.pengeluaran.dari-supplier.tambah',
          params: { id: this.getOrder.data.id },
        });
      }
    },
    onValidate(data) {
      const payload = {
        data: {
          type: 'stock-movements',
          id: data.relationships['stock-movements'].data[0].id,
          attributes: {
            is_valid_for_shipment: true,
          },
        },
      };
      this.updateStockMovement(payload).then((response) => {
        if (response) {
          this.onReload();
        }

        this.openDetail = false;
      });
    },
    onCLose() {
      this.openDetail = false;
    },
    onRefresh() {
      this.openDetail = false;

      this.onReload();
    },
    onRemove(order) {
      this.deleteStockMovement({
        id: order.relationships['stock-movements'].data[0].id,
      }).then(() => {
        this.openDetail = false;
        this.onRefresh();
      });
    },
    onViewStockMovement(stockMovementId) {
      this.fetchStockMovement({
        id: stockMovementId,
        include:
          'product-batches.product,destination-office,origin-office,order',
      }).then(() => {
        this.openDetail = false;
        this.openDetailStockMovement = true;
      });
    },
    onCloseViewStockMovement() {
      this.openDetailStockMovement = false;
      this.openDetail = true;
    },
    onRefreshStockMovement() {
      this.openDetailStockMovement = false;
      this.openDetail = false;
    },
  },
  created() {
    this.onCreated();
  },
};
</script>
